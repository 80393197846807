<template>
<v-app>
    <v-main style="padding: 0;">
        <v-container fluid>
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <loading-overlay :loading="loading" />
              </v-col>
            </v-row>
            <div v-if="isVerified && !loading">
                <md-dialog-alert :md-active.sync="isDisplayDialog" :md-content="dialog.dialogMessage" :md-title="dialog.dialogTitle" md-confirm-text="OK" @md-closed="backToMerchant" />
                <v-row align="center" justify="center">
                    <v-col align="center" justify="center">
                      <span class="transaction-title">
                          You are being charged by
                      </span>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col align="center" justify="center">
                    <v-img :src="merchantLogo" @load="imageLoaded" alt="merchant logo" contain max-height="85" />
                    <span v-if="!isImageLoaded" class="transaction-title">
                      {{getMerchantname()}}
                    </span>
                  </v-col>
                </v-row>
                <div v-if="customerData && customerData.description">
                    <v-row>
                      <v-col>
                        <span class="transaction-label">Description:</span>
                        <span class="breakdown-value ml-3">{{customerData ? customerData.description: '-'}}</span>
                      </v-col>
                    </v-row>
                </div>
                <v-row>
                  <v-col>
                    <span class="transaction-label">Transaction Total Amount:</span>
                    <span class="breakdown-value ml-3">{{tokenValues ? '₱' + getPayableAmount(tokenValues[6]) : '-'}}</span>
                  </v-col>
                </v-row>
                <v-radio-group v-model="paymentOptionList" column>
                    <v-radio value="default">
                      <template v-slot:label>
                        <v-row justify="space-between">
                          <v-col cols="7"> {{defaultOptionLabel}}
                          </v-col>
                          <v-col cols="5" class="right-align" v-if="tokenValues.length > 0">{{getPayableAmount(tokenValues[2])}} {{tokenValues[3]}}
                          </v-col>
                        </v-row>
                      </template>
                    </v-radio>
                    <div v-if="paymentOptionList === 'default'" class="mt-1 mb-6">
                      <v-container v-if="!hasBalanceComputation">
                        <v-row
                          class="fill-height"
                          align-content="center"
                          justify="center"
                        >
                          <v-col
                            class="text-subtitle-1 text-center"
                            cols="12"
                          >
                            Computing your balance
                          </v-col>
                          <v-col cols="6">
                            <v-progress-linear
                              color="deep-purple accent-4"
                              indeterminate
                              rounded
                              height="6"
                            ></v-progress-linear>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-container v-else-if="defaultOptionLabel == 'GCASH'">
                        <break-down class="mt-4 pa-2" title="Amount to Pay:" :list="paymentOption" :amount="amountToPay" :selected-payment-method="selectedPaymentOption" :data="customerData" button-label="Continue" :button="submit"/>
                      </v-container>
                      <v-container v-else-if="defaultOptionCode == 'landbank'">
                        <v-card >
                          <v-row>
                            <span class="transaction-label">Transaction Details:</span>
                          </v-row>
                          <v-row>
                              <v-col class="d-flex" cols="12" md="4">
                                  <v-flex xs10>
                                      <span>Account Name:</span>
                                  </v-flex>
                              </v-col>
                              <v-col class="d-flex" cols="12" md="3">
                                  <v-flex xs12>
                                      {{customerData.accountName}} 
                                  </v-flex>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col class="d-flex" cols="12" md="4">
                                  <v-flex xs10>
                                      <span>Account Number:</span>
                                  </v-flex>
                              </v-col>
                              <v-col class="d-flex" cols="12" md="3">
                                  <v-flex xs12>
                                      {{tokenValues[4]}}
                                  </v-flex>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col class="d-flex" cols="12" md="4">
                                  <v-flex xs10>
                                      <span>Mobile Number:</span>
                                  </v-flex>
                              </v-col>
                              <v-col class="d-flex" cols="12" md="3">
                                  <v-flex xs12>
                                      {{customerData.mobile}}
                                  </v-flex>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col class="d-flex" cols="12" md="4">
                                  <v-flex xs10>
                                      <span>Transaction Type:</span>
                                  </v-flex>
                              </v-col>
                              <v-col class="d-flex" cols="12" md="3">
                                  <v-flex xs12>
                                      {{customerData.transactionType}}
                                  </v-flex>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col class="d-flex" cols="12" md="4">
                                  <v-flex xs10>
                                      <span>Email:</span>
                                  </v-flex>
                              </v-col>
                              <v-col class="d-flex" cols="12" md="3">
                                  <v-flex xs12>
                                      {{customerData.email}}
                                  </v-flex>
                              </v-col>
                          </v-row>
                        </v-card>
                        <break-down class="mt-4 pa-2" title="Amount to Pay:" :list="paymentOption" :amount="amountToPay" :selected-payment-method="selectedPaymentOption" :data="customerData" button-label="Confirm" :button="submit"/>
                      </v-container>
                      <v-container v-else>
                        <break-down class="mt-4 pa-2" title="Amount to Pay:" :list="paymentOption" :amount="amountToPay" :selected-payment-method="selectedPaymentOption" :data="customerData" />
                        <break-down class="mt-1 pa-2" :title="`Balance of ${customerData.mobile}:`" :list="balanceBreakdown" :button="submit" :selected-payment-method="selectedPaymentOption" :data="customerData" />
                      </v-container>
                    </div>
                </v-radio-group>
            </div>
        </v-container>
    </v-main>
</v-app>
</template>

<script>
import LoadingOverlay from '@/views/component/LoadingOverlay.vue';
import BreakDown from '@/views/component/BreakDown.vue';
import {
    mapGetters
} from 'vuex';
import API from '@/api/API'
import {
    getDetailsFromToken,
    isValidV2Parameter,
    invalidParameterErrorCode,
    convertParamStringToArray,
    getBalanceBreakdownV2,
    getBalanceBreakdown,
    getBalanceV2Parameters,
    formatAmount,
    getChargeParametersV2,
    successChargeV2,
    errorCharge,
    isError500,
    splitCamelCaseToString,
    shouldRedirectbackToMerchant,
} from '@/utils/params.js';

export default {
    name: 'Transaction',
    components: {
        LoadingOverlay,
        BreakDown
    },
    computed: {
        ...mapGetters([
            'customerData'
        ]),
        balance(item) {
            return
        }
    },
    data: () => ({
        loading: true,
        isVerified: false,
        paymentOption: [],
        balanceBreakdown: [],
        balances: {},
        amountToPay: [],
        paymentOptionList: 'default',
        data: {},
        isDisplayDialog: false,
        dialog: {
            dialogMessage: '',
            dialogTitle: '',
            redirectUrl: ''
        },
        isImageLoaded: false,
        merchants: {},
        merchantLogo: '',
        defaultOptionLabel: undefined,
        tokenValues: [],
        selectedPaymentOption: {},
        hasBalanceComputation: false,
    }),
    mounted() {
        console.log('mounted')
        this.$store.dispatch('NavbarState', {state: false});
        this.verify()
    },
    watch: {
        balances(val) {
            console.log(val)
        },
        deep: true
    },
    methods: {
        async verify() {
          console.log('verify')
            this.isVerified = false
            this.loading = true
            if (isValidV2Parameter(this.customerData)) {
                this.paymentOption = convertParamStringToArray(this.customerData.PO)
                this.balanceBreakdown = getBalanceBreakdown(this.paymentOption)
                this.amountToPay = convertParamStringToArray(this.customerData.amount)
                this.tokenValues = getDetailsFromToken(this.customerData)
                this.defaultOptionLabel = this.tokenValues[1]
                this.defaultOptionCode = this.tokenValues[0]
                this.selectedPaymentOption = {
                  code: this.tokenValues[0],
                  name: this.tokenValues[1],
                  amountToPay: this.tokenValues[2],
                  label: this.tokenValues[3],
                  transactionAmount: this.tokenValues[6],
                  timestamp: this.tokenValues[7]
                }
                console.log(this.selectedPaymentOption)
                if(this.selectedPaymentOption.code == "GCASH") {
                  this.hasBalanceComputation = true
                  this.isVerified = true
                  this.loading = false
                  console.log("gcash")
                } else if(this.selectedPaymentOption.code == "landbank") {
                  this.hasBalanceComputation = true
                  this.isVerified = true
                  this.loading = false
                  console.log("landbank")
                } else {
                  this.getBalance()
                }
            } else {
              const errorCode = invalidParameterErrorCode(this.customerData)
              console.log('401: Invalid parameters - ' + errorCode)
              this.$router.replace({path:'401', query: { errorCode: errorCode }})
            }
        },
        async getBalance() {
          console.log('balance')
          const params = getBalanceV2Parameters(this.customerData, this.selectedPaymentOption, "payment")
          const response = await API.getBalanceV2(params)
          setTimeout(() => {
            if(response.statusCode == "200") {
              const accounts = response.resultDetails
              this.balanceBreakdown = getBalanceBreakdownV2(response.resultDetails, this.selectedPaymentOption)
              const index = this.balanceBreakdown.findIndex(x => x.key === this.customerData.AN)
              this.balanceBreakdown = [this.balanceBreakdown[index]]
              this.hasBalanceComputation = true
              this.isVerified = true
            } else {
              console.log(response.error)
              console.log('401: ' + JSON.stringify(response.error.message ?? response.error))
              this.hasBalanceComputation = true
              if((response.error.message ?? response.error).includes("Not Found")) {
                this.$router.replace({path:'404', query: { errorCode: '20001' }})
              } else if(response.error.message === "Network Error") {
                this.$router.replace({path:'404', query: { errorCode: '20002' }})
              } else if(response.error.includes("Bad Gateway")) {
                this.$router.replace({path:'500', query: { errorCode: '20003' }})
              } else if((response.error.message ?? response.error).includes("Gateway Time-out")) {
                this.$router.replace({path:'500', query: { errorCode: '20004' }})
              } else {
                this.$router.replace({path:'401', query: { errorCode: '10200' }})
              }
            }
          }, 2000)
            this.loading = false
        },
        imageLoaded() {
          this.isImageLoaded = true
        },
        getMerchantname() {
          return this.customerData ? splitCamelCaseToString(this.customerData.MC): '-'
        },
        back() {
            this.$router.back()
        },
        getPayableAmount(amount) {
          return formatAmount(amount)
        },
        submit() {
          this.charge()
        },
        async charge() {
            this.loading = true
            const params = getChargeParametersV2(this.customerData, this.selectedPaymentOption)
            const verify = await API.chargeV2(params)
            if (verify && verify.statusCode === '200') {
                if(verify.checkoutUrl && verify.isThirdPartyGateway) {
                  if(this.selectedPaymentOption.code == "landbank") {
                    window.location.replace(verify.checkoutUrl)
                  } else {
                    window.location.replace(verify.checkoutUrl)
                  }
                } else {
                  this.dialog = successChargeV2(this.customerData, verify)
                  this.loading = false
                  this.isDisplayDialog = true
                }
            } else {
                this.dialog = errorCharge(verify, verify.failUrl)
                this.loading = false
                this.isDisplayDialog = true
            }
        },
        backToMerchant() {
            window.parent.postMessage(JSON.stringify({title: this.dialog.dialogTitle, message: this.dialog.dialogMessage}), '*')
            if (!isError500(this.dialog.redirectUrl)) {
                if (shouldRedirectbackToMerchant(this.dialog.dialogMessage)) {
                    this.loading = true
                    var url = this.dialog.redirectUrl
                    var pattern = new RegExp(/\?.+=.*/g);
                    if (pattern.test(url)) {
                        url = `${url}&CRN=${this.customerData.CRN}`
                    } else {
                        url = `${url}?CRN=${this.customerData.CRN}`
                    }
                    window.location.replace(url)
                }
            } else {
                this.$router.replace({path:'500', query: { errorCode: '10008' }})
            }
        }
    },

};
</script>

<style scoped>
.transaction-label {
    font-weight: bold;
    font-size: 1em;
}

.transaction-title {
    font-weight: 500;
    font-size: 1.2em;
    text-align: center;
}

.breakdown-label {
    font-weight: 400;
    font-size: 1em;
    color: black;
}

.breakdown-value {
    font-weight: 400;
    font-size: 1em;
    color: black;
}
</style>
